* {
  outline: none !important;
}

/* Basic definitions classes */
.card {
  margin: 1rem;
  border: 0.1rem solid var(--secondary, #fefefe);
  padding: 1rem;

  & > .card-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.basic-grid {
  flex: var(--c, 1);
  margin: var(--margin);
}

.basic-code {
  font: 1rem/1.6 var(--mono);
  border-radius: var(--roundness);
}

.basic-header {
  margin: 0.6em 0;
  font-size: 1rem;
  font-weight: normal;
}

/* General */
*,
::after,
::before {
  box-sizing: border-box;
}

html {
  text-size-adjust: 100%;
}

body {
  color: var(--color);
  margin: 0;
  background-color: var(--background);
}

main {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;

  & .info-div {
    & details > summary {
      list-style: none;
    }
    & details > summary::-webkit-details-marker {
      display: none;
    }

    & details {
      & summary:hover {
        background-color: var(--primary, #fefefe);
      }

      &[open] {
        & summary:hover {
          background-color: white;
        }

        transition: border 0.5s linear;

        & summary ~ * {
          animation-name: fadeInDown;
          animation-duration: 0.5s;
        }
      }

      &:not(:first-of-type) {
        border-top: none;
      }

      & > summary {
        cursor: pointer;
        padding: 0 0.2rem;
        transition: background-color 0.5s linear;
        user-select: none;
      }
    }
    & #info-click {
      font-size: small;
    }

    & #info-summary {
      display: flex;
      justify-content: center;
      margin: 10px 0 10px 0;
      font-size: medium;
    }

    & #info-icons {
      display: flex;
      justify-content: center;
      font-size: small;

      & .info-span {
        margin: 0 1rem 0 1rem;
      }

      & span {
        display: flex;
        align-items: center;
      }
    }
  }
}

iframe {
  border: none;
  max-width: 100%;
}

/* Text */
a {
  color: var(--accent);
  text-decoration: none;

  &:hover {
    color: var(--accent);
    text-decoration: underline;
  }
}

p,
li,
ul,
ol,
dl,
dd,
figcaption {
  margin: 0;
}

hr {
  border: 0;
  border-bottom: 1px solid var(--color);
}

pre {
  @extend .basic-code;

  padding: 1rem;
  overflow: auto;
  background: var(--primary);
}

code {
  @extend .basic-code;
}

label {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

blockquote {
  margin: 0;
  padding: 1rem 1.5em;
  border-left: 5px solid var(--primary);
}

/* Header */
h1 {
  @extend .basic-header;
  font-size: 2.625rem;
  line-height: 1.2;
}

h2 {
  @extend .basic-header;
  font-size: 1.625rem;
  line-height: 1.2;
}

h3 {
  @extend .basic-header;
  font-size: 1.3125rem;
  line-height: 1.24;
}

h4 {
  @extend .basic-header;
  font-size: 1.1875rem;
  line-height: 1.23;
}

h5,
h6 {
  @extend .basic-header;
  font-weight: bold;
}

/* Grid */
section {
  display: flex;
  flex-flow: row wrap;
}

aside,
section > section {
  @extend .basic-grid;
}

article {
  @extend .basic-grid;
  padding: 1em;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  background: var(--primary);
  border-radius: var(--roundness);

  & > * + * {
    margin-top: 1em;
  }
}

/* Image */
img {
  border: none;
  display: block;
  max-width: 100%;
  border-radius: var(--roundness);
}

figure {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    object-fit: contain;
  }
}

/* Table */
th,
td {
  border: 1px solid;
  padding: 0.4em;
  text-align: left;
  vertical-align: top;
}

thead,
tfoot {
  background: var(--primary);
}

table {
  margin: 1em 0;
  border-spacing: 0;
  border-collapse: collapse;
}

footer {
  width: max-content;
  right: 0px;
  bottom: 0px;
  height: 70px;
  z-index: 0;
  display: flex;
  padding: 0.5rem 1rem;
  position: fixed;
  justify-content: flex-end;
  text-align: right;

  & a {
    font-size: small;
    color: black;
  }

  & span {
    font-size: small !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    animation-duration: 0.01ms !important;
    transition-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@media only screen and (min-width: 1050px) {
  .container {
    width: 95%;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1280px) {
  .container {
    width: 75%;
    margin-top: 2rem;
  }
}

/* TODO: Below shouldn't be here */

#alert {
  top: 0;
  gap: 12px;
  width: 20%;
  right: 20px;
  display: flex;
  position: fixed;
  flex-wrap: wrap;
  min-width: 250px;
  padding-top: 1rem;
  padding-right: 1rem;
  flex-direction: column-reverse;
}

dialog-modal {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &.invisible {
    opacity: 0;
    visibility: visible;
  }
}

/* FieldSet Icons */

.fieldset-with-icon {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-color: var(--primary);
}
.icon-bottom-text {
  margin-top: -0.5rem;
  color: var(--accent);
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: var(--accent, #fff);
  box-shadow: 0 -10px 15px 5px var(--background, #ff8000);
  max-height: 4rem;
}

.navbar-logo {
  display: flex;
  align-self: flex-start;
  margin: 0 1rem;
  & > h2 > a {
    color: var(--color-on-accent, #000);
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-shadow: horizontal-offset vertical-offset var(--primary);
    }
  }
  & img {
    margin: 0;
    width: 3rem;
    padding: 0;
  }
}

.navbar-project {
  display: flex;
  margin: 0 1rem;
  flex-direction: column;
  justify-content: center;
  & > label {
    color: var(--primary);
    font-size: small;
  }
  & > select {
    padding: 0.3rem 0 0.3rem 0;
  }
}

.navbar-user {
  margin: 0 0.5rem;
  width: 7rem;

  & #username {
    font-weight: bold;
  }
}

nav > navbar-items {
  display: flex;
  flex: auto;

  & li:hover {
    & a {
      color: var(--accent);
      text-decoration: none;
    }
  }

  & a {
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    width: 100%;
    height: 100%;
    &:hover {
      text-decoration: none;
    }
    & > * {
      pointer-events: none;
    }
  }
}

// Tables

.enable-hover-indication {
  & form:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 2px #721c24;
  }
}

.enable-click-indication {
  & form {
    cursor: pointer;
    & :active {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
